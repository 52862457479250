import logo from '../../images/logo.png'
import { Link } from "react-router-dom";
import styles from '../css/index.module.css'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import DeviceDetector from "device-detector-js";
import { Button, Logo, MenuTrigger } from '../style/headerStyle';
import axios from 'axios';
import { getAllInfoByISO } from "iso-country-currency"
import Cookies from 'js-cookie'
import CurrencyFormat from 'react-currency-format';
import { QRCode } from 'react-qrcode-logo';
const IndexHeader = () => {

  document.title = `Xattle | Home`;
  const navigate = useNavigate();
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [email, setEmail] = useState()
  const [accountBalance, setAccountBalance] = useState()
  const [currencyName, setCurrencyName] = useState()
  const [verified, setVerified] = useState()
  const [currencySymbols, setCurrencySymbols] = useState()
  useEffect(() => {

    const check = Cookies.get('__isLoggedIn')

    if (check) {
      const userInfo = localStorage.getItem('_currentInfo')
      const currInfo = JSON.parse(localStorage.getItem('OMS__CUR'))
      setisLoggedIn(true)

      const users = JSON.parse(userInfo)
      setEmail(users.email)
      setAccountBalance(users.deposit)
      if (users.verificationStatus === "0") {
        setVerified('Invalid user')
      } else if (users.verificationStatus === "1") {
        setVerified('Unverified')
      } else if (users.verificationStatus === "2" ) {
        setVerified('Under Review')
      } else if (users.verificationStatus === "3" ) {
        setVerified('Verified')
      }

      setCurrencySymbols(currInfo.symbol)
      setCurrencyName(currInfo.name)

      axios.post('https://xattle-api.fegigtech.com/auth/getDeviceInfo', {})
        .then((response) => {
          return (response.data)
        })
        .then((data) => {
          setFlag((data.country).toLowerCase())
        })
    }
    else {

      const deviceDetector = new DeviceDetector();
      axios.post('https://xattle-api.fegigtech.com/auth/getDeviceInfo', {})
        .then((response) => {
          return (response.data)
        })
        .then((data) => {
          //setFlag((data.country).toLowerCase())
          const userAgent = data.deviceInfo;
          const device = deviceDetector.parse(userAgent);
          localStorage.setItem('OMS__FEI', JSON.stringify(device))
          setFlag((data.country).toLowerCase())
          setCountry(data.country)
          const count = getAllInfoByISO(`${(data.country).toLowerCase()}`)
          localStorage.setItem('OMS__CUR', JSON.stringify({ name: count.currency, symbol: count.symbol }))
        })
      setisLoggedIn(false)
    }


  }, [navigate])

  // const accountBalance =  props.userDetails.depositBalance
  let [theme, setTheme] = useState()


  const setDark = () => {
    localStorage.setItem("theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");

  };

  const setLight = () => {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
    setTheme(<i className="fi fi-sr-moon"></i>)
  };

  const storedTheme = localStorage.getItem("theme");

  const prefersDark =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  const defaultDark =
    storedTheme === "dark" || (storedTheme === null && prefersDark);

  if (defaultDark) {
    setDark();

  }
  const [toggler, toggleTheme] = useState(defaultDark)

  if (toggler) {
    //console.log(toggler)
    theme = <i className="fi fi-sr-sun"></i>
  } else {
    theme = <i className="fi fi-sr-moon"></i>
  }
  const switchTheme = () => {

    if (theme.props.className === 'fi fi-sr-sun') {
      setLight();
      setTheme(<i className="fi fi-sr-moon"></i>)
      toggleTheme(false)
    }
    else if (theme.props.className === 'fi fi-sr-moon') {
      setDark();
      setTheme(<i className="fi fi-sr-sun"></i>)
      toggleTheme(true)
    }
  }

  //   const logout =() =>{
  //     Cookies.remove('__isLoggedIn')
  //     navigate('/login')
  //     localStorage.clear()
  //   }
  const [Flag, setFlag] = useState()
  const [Country, setCountry] = useState()
  const [isMobile, setIsMobile] = useState(true)


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 767) {
        setIsMobile(false)
      }
      else if (window.innerWidth < 767) {
        setIsMobile(true)
      }
    }
    handleResize()
    window.addEventListener("resize", handleResize)

  }, [isMobile])

  const logout = () => {
    setisLoggedIn(false)
    Cookies.remove('__isLoggedIn')
    localStorage.clear()
    navigate('/')

  }
  const mobileMenu = () => {
    return (<>
      <div className={`${styles.menu_body}`}>
        <Link className={` ${styles.link_icons} ${styles.menu_link} nav-link`} to="#" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fi fi-sr-wallet"></i>
        </Link>
        <ul className={`dropdown-menu ${styles.menu_dropdown} `}>
          <Link className={`dropdown-item ${styles.items} `} to='/home/wallet'>
            <div className={styles.user_card_icon_body}>
              <i className={`fi fi-sr-apps ${styles.user_card_icons}`}></i> </div>
            <div className={styles.user_card_text_body}>
              <div className={styles.user_card_text_inner}>Overview</div>
            </div>
          </Link>
          <Link className={`dropdown-item ${styles.items} `} to="/home/wallet/spot">
            <div className={styles.user_card_icon_body}>
              <i className={`fi fi-sr-apps-add ${styles.user_card_icons}`}></i> </div>
            <div className={styles.user_card_text_body}>
              <div className={styles.user_card_text_inner}>Spot and Fiat</div>
            </div>
          </Link>
          <Link className={`dropdown-item ${styles.items} `} to="/home/connectWallect">
            <div className={styles.user_card_icon_body}>
              <i className={`fi fi-sr-link-alt ${styles.user_card_icons}`}></i> </div>
            <div className={styles.user_card_text_body}>
              <div className={styles.user_card_text_inner}> External Wallets</div>
            </div>
          </Link>
        </ul>
      </div>
      <div className={`${styles.menu_body}`}>
        <Link className={`${styles.link_icons} ${styles.menu_link}  nav-link `} to="#" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fi fi-sr-portrait"></i>
        </Link>
        <ul className={`dropdown-menu ${styles.menu_dropdown} ${styles.user_card}`}>
          <div className={`${styles.user_card_head} `}>
            <div className={`${styles.user_card_head_top} `}>
              {email}
            </div>
            <div className={`${styles.user_card_head_buttom} `}>
              <div className={styles.user_card_head_balance}>
                <CurrencyFormat value={accountBalance} displayType={'text'} decimalScale={2} fixedDecimalScale={true} isNumericString={true} thousandSeparator={true} prefix={`${currencySymbols}`} />

              </div>
              <Link to="#" className={styles.user_card_head_verification}>
                <i className="fi fi-sr-file-delete"></i> {verified}
              </Link>
            </div>
          </div>
          <div className={styles.user_card_body}>

          </div>
          <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="/home/dashboard">
            <div className={styles.user_card_icon_body}>
              <i className={`fi fi-sr-home ${styles.user_card_icons}`}></i> </div>
            <div className={styles.user_card_text_body}>
              <div className={styles.user_card_text_inner}>Dashboard</div>
            </div>
          </Link>
          <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="/home/order">
            <div className={styles.user_card_icon_body}>
              <i className={`fi fi-sr-signal-alt-2 ${styles.user_card_icons}`}></i> </div>
            <div className={styles.user_card_text_body}>
              <div className={styles.user_card_text_inner}>Orders</div>
            </div>
          </Link>
          <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="/home/security">
            <div className={styles.user_card_icon_body}>
              <i className={`fi fi-sr-fingerprint ${styles.user_card_icons}`}></i> </div>
            <div className={styles.user_card_text_body}>
              <div className={styles.user_card_text_inner}>Security</div>
            </div>
          </Link>
          <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="/home/verification">
            <div className={styles.user_card_icon_body}>
              <i className={`fi fi-sr-document ${styles.user_card_icons}`}></i> </div>
            <div className={styles.user_card_text_body}>
              <div className={styles.user_card_text_inner}>Verification</div>
            </div>
          </Link>
          <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="/home/affiliate">
            <div className={styles.user_card_icon_body}>
              <i className={`fi fi-sr-comment-user ${styles.user_card_icons}`}></i> </div>
            <div className={styles.user_card_text_body}>
              <div className={styles.user_card_text_inner}>Affiliate Program</div>
            </div>
          </Link>
          <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="/home/support">
            <div className={styles.user_card_icon_body}>
              <i className={`fi fi-sr-life-ring  ${styles.user_card_icons}`}></i> </div>
            <div className={styles.user_card_text_body}>
              <div className={styles.user_card_text_inner}>Help/Support</div>
            </div>
          </Link>
          <div className={`dropdown-item center ${styles.items} ${styles.user_card_body_link}`}>
            <div className={styles.user_card_qr}>
              <div className={styles.user_card_qr_content} >
                <QRCode value={`referral Link comes Here ${email}`} size={100} level={'M'}
                  eyeRadius={[[10, 10, 0, 10], [10, 10, 10, 0], [10, 0, 10, 10],]}
                  logoImage={logo} logoOpacity={1} qrStyle={'squares'} style={{ borderRadius: '10px' }} className="rounded" />
              </div>
            </div>
            <button onClick={logout} className={`ms-auto btn main-background ${styles.user_card_head_verification}`}>
              <div className={styles.user_card_icon_body}>
                <i className="fi fi-sr-power"></i></div>
              <div className={styles.user_card_logout}>
                <div className={styles.user_card_text_inner}>Log Out</div>
              </div>
            </button>
          </div>
        </ul>
      </div>

    </>)
  }
  return (
    <>
      <header className={`${styles.dark_bb} navbar-dark`}>
        <nav className={`${styles.navbar} navbar navbar-expand-lg`}>
          <Link className="navbar-brand" to="/">
            <Logo src={logo} alt="logo" /></Link>
          <MenuTrigger type="button" data-bs-toggle="collapse" data-bs-target="#headerMenu" aria-controls="headerMenu" aria-expanded="false" aria-label="Toggle navigation" className="collapsed">
            <span></span>
            <span></span>
            <span></span>
          </MenuTrigger>

          <div className="collapse navbar-collapse" id="headerMenu">
            {!isMobile ? <></>
              : <>
                <div className={`${styles.menu} ${styles.baseMobile} ms-auto`}>
                  {isLoggedIn ? <></> :

                    <>


                      <div className={styles.menu_body}>
                        <Link className={`${styles.menu_link} nav-link `} to="/login" role="button">
                          <i className="fi fi-sr-user"></i> Log In
                        </Link>

                      </div>

                      <div className={`${styles.menu_body} `}>
                        <Button className={`${styles.menu_link} ${styles.link_icons} btn`} onClick={(e) => { navigate('/register') }}>
                          <i className="fi fi-sr-user-add"></i> Register
                        </Button>
                      </div>
                    </>}
                </div>
              </>}
            <div className={`${styles.menu} ${styles.firstMobile} me-auto`}>
              <div className={styles.menu_body}>
                <Link className={`${styles.menu_link} nav-link `} to="market" role="button">
                  Markets
                </Link>

              </div>
              <div className={`${styles.menu_body}`}>
                <Link className={`${styles.menu_link} nav-link`} to="about" role="button">
                  About Us
                </Link>
              </div>

              <div className={styles.menu_body}>
                <Link className={`${styles.menu_link} nav-link `} to="help" role="button">
                  Help Center
                </Link>

              </div>
              <div className={styles.menu_body}>
                <Link className={`${styles.menu_link} nav-link `} to="insights" role="button">
                  Insights
                </Link>

              </div>

            </div>


            <div className={`${styles.menu} ${styles.baseMobile} ms-auto`}>
              {isLoggedIn ? <>
                {mobileMenu()}
              </> : <>
                {isMobile ? <></> : <>
                  <div className={styles.menu_body}>
                    <Link className={`${styles.menu_link} nav-link `} to="/login" role="button">
                      <i className="fi fi-sr-user"></i> Log In
                    </Link>

                  </div>

                  <div className={`${styles.menu_body} `}>
                    <Button className={`${styles.menu_link} ${styles.link_icons} btn`} onClick={(e) => { navigate('/register') }}>
                      <i className="fi fi-sr-user-add"></i> Register
                    </Button>
                  </div>
                </>}

              </>}
              {isLoggedIn ? <><div className={`${styles.menu_body}`}>
                <Link className={`${styles.menu_link}  nav-link`} to="#" type="button" >
                  <i className={`fl fl-${Flag}`}></i> | {currencyName}
                </Link>
              </div></> : <>
                <div className={`${styles.menu_body}`}>
                  <Link className={`${styles.menu_link}  nav-link`} to="#" type="button" >
                    <i className={`fl fl-${Flag}`}></i> | {Country}
                  </Link>
                </div>

              </>}

              <div className={`${styles.menu_body}`}>
                <Link className={`${styles.menu_link} ${styles.link_icons} nav-link `} to="#" type="button" onClick={switchTheme}>
                  {theme}
                </Link>
                <input type="checkbox" id="checkbox" onChange={(e) => toggleTheme(e.target.checked)} defaultChecked={toggler}
                  style={{ display: 'none' }} />
              </div>
            </div>

          </div>
        </nav>
      </header>

    </>
  );
}
export default IndexHeader;