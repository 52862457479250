import axios from "axios";
import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import styles from '../../style/main.module.css'
import { convertTime } from "../wallet/components/getTime";
import { NotFound, NotFoundIcon, NotFoundText, Table, Td, Tr } from "./style";
const ReferralTable = () => {
    const userId = JSON.parse(localStorage.getItem('_currentInfo')).user_id;
    const currency = JSON.parse(localStorage.getItem('OMS__CUR'))
    const [referrals, setReferrals] = useState([]);
    const [isEmpty, setIsEmpty] = useState(false);
    useEffect(() => {
        axios.post('https://xattle-api.fegigtech.com/user/getReferrals', { userID: userId })
        .then((response) => {
          
            if (response.data.data.length === 0) {
                setIsEmpty(true)
            }
            else {
                setReferrals(response.data.data);
                setIsEmpty(false)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    },[userId])
    const getReward = (bool)=>{
        if (bool) {
            return (<div><CurrencyFormat value={200} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
            isNumericString={true} thousandSeparator={true} prefix={`${currency.symbol}`} /></div>)
        }
        else {
            return (<div>No Reward</div>)
        }
    }
    return ( <>
         <div className={`${styles.card} ${styles.half}`}>
                <div className={styles.card_inner}>
                    <div className={styles.card_content}>
                        <div className={styles.card_header}>
                            <div className={styles.card_header_text}>My Referrals</div> 
                            </div>
                            <div className={styles.card_body}>
                            <div className={styles.table}>
                                {isEmpty ? <><NotFound>
                                    <NotFoundIcon className="fi fi-rr-search-alt">
                                    </NotFoundIcon>
                                    <NotFoundText>You have no referrals yet.</NotFoundText>
                                </NotFound></>

                                 : <Table className={`table`}>
                                    <thead>
                                        <Tr>
                                            <th>User ID</th>
                                            <th>Registered</th>
                                            <th>Rewarded</th>
                                        </Tr>
                                    </thead>
                                    <tbody>
                                        {referrals.map((referral) => {
                                            return <Tr key={referral.refId}>
                                                <Td>{referral.user}</Td>
                                                <Td>{convertTime(referral.date)}</Td>
                                                <Td>{getReward(referral.rewarded)}</Td>
                                            </Tr>
                                        }
                                        )}
                                    </tbody>
                                </Table>}
                            </div>
</div>
                       

                  
                    </div>
                </div>
            </div></> );
}
 
export default ReferralTable;