import axios from "axios"
import { useEffect, useState } from "react"
import styles from '../../style/main.module.css'
import { convertTime } from "../wallet/components/getTime"
import { BUY, FlexColumn, NotFound, NotFoundIcon, NotFoundText, SELL, Table, Td, Tr, TradePrice, YellowButtonLink, } from "./tradeComponents"

const OpenTrades = (props) => {
    const userID = props.userId
    const [tradeInfo, setTradeInfo] = useState([])
    const [isEmpty, setIsEmpty] = useState(true)
    //  const buy ={
    //     color:'green',
    //     textTransform: 'uppercase'
    //     }

    const checkType = (item) => {  if (item === 'buy') { return (<BUY>BUY </BUY>)   
    }
else{ return (<SELL>SELL </SELL>)}}

    useEffect(() => {
        axios.post('https://xattle-api.fegigtech.com/user/getOpenTrades', { userID: userID })
            .then(response => {
                return response.data.data

            }).then(data => {
                //console.log(data)
                if (data.length > 0) {
                    setTradeInfo(data.slice(0, 5))
                    setIsEmpty(false)
                }
                else {
                    setTradeInfo([])
                    setIsEmpty(true)
                }
            }).catch(err => {
                console.log(err)
                return err
            })
    }, [userID]);

    const closeTrade = (tradeID) => {
        axios.post('https://xattle-api.fegigtech.com/user/closeTrade', { tradeID: tradeID })
            .then(() => {
              // console.log(response)

            }).catch(err => {
              //  console.log(err.response)
        
            })
    }

    return (<>
        <div className={styles.table}>
            {isEmpty ? <><NotFound>
                <NotFoundIcon className="fi fi-rr-search-alt">
                </NotFoundIcon>
                <NotFoundText>No Opened Order</NotFoundText>
            </NotFound></>
                : <Table className={`table`}>
                    <thead>
                        <tr>

                            
                            <th scope="col">Pair</th>
                            <th scope="col">Option</th>
                            <th scope="col">Opened</th>
                            <th scope="col">Invested</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tradeInfo.map(info => (
                            <Tr key={info.tradeId}>
                                <Td><FlexColumn><div>{info.pair}</div></FlexColumn></Td>
                                <Td><FlexColumn>{checkType(info.option)} <TradePrice> @{info.entryPrice}</TradePrice></FlexColumn></Td>
                                <Td><FlexColumn><div>{convertTime(info.entryTime)}</div> </FlexColumn></Td>
                                <Td><FlexColumn><div>{info.invested}{info.currency}</div></FlexColumn></Td>
                                <Td><div><YellowButtonLink onClick={(e)=>{closeTrade(info.tradeId)}}>Close Trade</YellowButtonLink></div></Td>
                            </Tr>

                        ))}
                    </tbody>
                </Table>}
        </div>
    </>);
}

export default OpenTrades;