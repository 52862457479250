import styled from 'styled-components';

export const Main = styled.div`
    margin: 0 auto;
    flex-direction: column;
    display: flex;
    width: 100%;
    align-items: center;
    place-content: center;
    text-align:center;
    @media only screen and (max-width: 728px) {
        margin: 30px 0;
        padding: 10% !important;
        width: 100%;
      }
    `
export const Content = styled.div`
    display: flex;
    flex-direction: column;
 
    ${(props)=>props.styling}
    `
export const Title = styled.div`
      margin: 0;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin-top: 0;
    color: var(--font-color);
    padding: 10px 0 20px;
    position: relative;
    text-transform: uppercase;
    `
export const Wrapper = styled.div`
    background-color: var(--background-color);
    position: relative;
    overflow: hidden;
    display: flex;
    height:100vh;
    ${(props)=>props.styling}`
 
export const Text = styled.div`
    color: var(--font-color);
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;`

export const Text2 = styled.div`
    color: var(--font-color);
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;`

export const Logo = styled.div`
    padding: 15px;
    background-color: var(--bs-body-color);
    `
export const CardBody = styled.div`
    padding: 0 15%;
    margin: 20px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around; 
    .active{
        color: var(--font-color);
        text-decoration: none;
        background-color: var(--main);
    };`
export const Card = styled.div`
padding: 25px;
border: 1px #f5f5f5 solid;
border-radius: 15px;
box-shadow: #cccccc 1px 1px 2px 0px;
margin: 10px 0;
display: flex;
flex-direction: column;
justify-content:space-around;`
export const CardHeader = styled.div`
  display: flex;
  ${Text}{
    font-size: 21px;
    font-weight: 700;
    color: inherit;
  }`

export const CurrencyList = styled.div` 
border-radius: 5px;
font-weight: 700;
box-sizing: border-box;
margin: 10px 0;
min-width: 0px;
display: flex;
position:relative;
align-items: center;
border: none;
outline: none;
padding-left: 0px;
padding-right: 0px;
width: 50%;
user-select: none;
cursor: pointer;
:hover{
  color: var(--font-color);
  text-decoration: none;
  background-color: var(--main);
}

@media screen and (min-width: 767px){
    height: 50px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 12px;
    width:50%;
}
@media screen and (min-width: 1023px){
    padding: 10px;
    width: 50%;
    display: flex;
    flex-direction: row;
    margin: 10px auto;
}
`
export const Span = styled.div`
display: flex;
margin: 0 auto;
border-radius: 10px;
font-weight: 700;

`
export const YellowText = styled.span`
color: var(--main);
`
export const FlexColumn = styled.div`
display: flex;
flex-direction: column;
width: -webkit-fill-available;`

export const AuthTextgroup = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
width: 100%;
position: relative;
min-height: 12px;
`
export const IconDiv = styled.div`
box-sizing: border-box;
    margin: 0;
    min-width: 0;`
export const AuthTextFlex = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
display: inline-flex;
position: relative;
align-items: center;
line-height: 1.6;
border: 1px solid rgb(71, 77, 87);
border-radius: 4px;
height: 48px;
background-color: transparent;
width: 100%;
input{
    color: var(--font-color);;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
}
input:-webkit-autofill{
    transition: color 99999s ease-in-out 0s, background-color 99999s ease-in-out 0s;
    -webkit-text-fill-color: var(--font-color);;
}
:hover{
    border-color: rgb(240, 185, 11);
}
${IconDiv}{
    flex-shrink: 0;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 14px;
}
.PhoneInput{ 
    width: 100%;
    padding: 0 10px;
}
.PhoneInputInput{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    width: 100%;
    height: 100%;
    padding: 0px;
    outline: none;
    border: none;
    background-color: inherit;
    opacity: 1;
}`

export const IconBody = styled.div`
box-sizing: border-box;
    margin: 0px 4px 0px 0px;
    min-width: 0px;
    display: flex;
    align-items: center;`
export const Icon = styled.i`
box-sizing: border-box;
    margin: 0px 0px 0px 4px;
    min-width: 0px;
    color: rgb(94,102,115);
    font-size: 20px;
    width: 1em;
    height: 1em;`
export const AuthTextbox = styled.input`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
width: 100%;
height: 100%;
padding: 0px;
outline: none;
border: none;
background-color: inherit;
opacity: 1;
`
export const AuthOTP = styled.input`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
width: 100%;
height: 100%;
padding: 0px;
outline: none;
border: none;
background-color: inherit;
opacity: 1;
text-align: center;
font-size: 20px !important;
letter-spacing: 20px;
`

export const Header = styled.header`
border-bottom: none;
background-color: var(--background-color);
position: fixed;
z-index: 999;
top: 0;
width: 100%;
@media screen and(min-width: 992px)
.navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
}
.fixedTop{
    position: fixed;
    top: 0;
    width: 100%;
    background: var(--background-color);
    z-index: 99;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}
`
export const Nav = styled.nav`
padding: 0.5rem 1rem !important;
    border-radius: 0;
    border: 0;
    margin-bottom: 0;
    display: flex;
    position: relative;

`
export const Menu = styled.div`

    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: flex;
    color: #eaedf1;
    align-items: center;
    pointer-events: auto;
    

@media screen and (min-width: 767px){
    pointer-events: auto;
   
}`
export const MenuBody= styled.div`

    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    outline: none;
    cursor: default;
    color: #edeff1;
    align-items: center;
    position: relative;
    text-decoration: none;
    margin-left: 8px;
    margin-right: 8px;
    height: 100%;
    .menuLink{ color: var(--font-color);
        font-weight: 400;
        font-size: 14px;
        text-transform: none;
        margin: 0;
        padding: 17px 5px;
        line-height: 14px;
        position: relative;
        font-family: 'Nunito', sans-serif;
        transition: .1s;}
        .menuLink:hover, .menuLink:focus, .menuLink:active {
        color: var(--main);
        font-weight: 500;
      }
`
export const menuLink= styled.div`
   .menuLink{ color: #fff;
    font-weight: 400;
    font-size: 14px;
    text-transform: none;
    margin: 0;
    padding: 17px 5px;
    line-height: 14px;
    position: relative;
    font-family: 'Nunito', sans-serif;
    transition: .1s;}
    .menuLink:hover, .menuLink:focus, .menuLink:active {
    color: var(--main);
    font-weight: 500;
  }
  `
  export const NavLogo = styled.img`
  padding-top: 0.5125rem;
  padding-bottom: 0.5125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
  width: 150px;
}`

export const TransparentButton = styled.button`
background: none;
outline: none;
border: none;`
 
export const AuthContainer = styled.div`
margin: 10px auto;
padding: 30% 4%;
width: 100%;
display: flex;
flex-direction: column;
@media screen and (min-width: 768px){
    padding: 10%;
    width: 75%;
}
@media screen and (min-width: 1024px){
    width: 50%;
    padding: 7.5%;
}`
export const TextOTP = styled.div`
margin-left:5px;
font-size: 14px;
line-height: 1;
color: var(--main);
cursor: pointer;
:hover{
    color: rgb(94,102,115);

}`

export const ErrorText = styled.div`
text-align: center; 
color: #ff7474;
font-style: italic;`

export const SuccessText = styled.div`
text-align: center; 
color: #74ff91;
font-style: italic;`

export const ResendText = styled.div`
margin-top: 10px;
justify-content: flex-end;
align-items: center;
display: flex;
line-height: 1;
font-size:11px
`
export const AuthContainerAlt = styled.div`

display: flex;
align-items: center;
margin: 0 auto;
place-content: center;
flex-direction: column;
padding: 30px !important;
text-align: center;
width: 100%;
max-width: 100%;
`

export const Space = styled.div`
margin-top: ${(props)=>props.margin};
`