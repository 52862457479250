import { Link } from "react-router-dom";
import { Header, Menu, MenuBody, Nav, NavLogo, TransparentButton } from "./componentStyles";
import logo from '../../images/logo.png'
import { useEffect, useState } from "react";
import DeviceDetector from "device-detector-js";
import axios from "axios";
import {getAllInfoByISO} from "iso-country-currency"

const AuthHeaders = () => {
    let [theme, setTheme] = useState()


    const setDark = () => {
        localStorage.setItem("theme", "dark");
        document.documentElement.setAttribute("data-theme", "dark");

    };

    const setLight = () => {
        localStorage.setItem("theme", "light");
        document.documentElement.setAttribute("data-theme", "light");
        setTheme(<i className="fi fi-sr-moon"></i>)
    };

    const storedTheme = localStorage.getItem("theme");

    const prefersDark =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches;

    const defaultDark =
        storedTheme === "dark" || (storedTheme === null && prefersDark);

    if (defaultDark) {
        setDark();

    }
    const [toggler, toggleTheme] = useState(defaultDark)

    if (toggler) {
        //console.log(toggler)
        theme = <i className="fi fi-sr-sun"></i>
    } else {
        theme = <i className="fi fi-sr-moon"></i>
    }
    const switchTheme = () => {

        if (theme.props.className === 'fi fi-sr-sun') {
            setLight();
            setTheme(<i className="fi fi-sr-moon"></i>)
            toggleTheme(false)
        }
        else if (theme.props.className === 'fi fi-sr-moon') {
            setDark();
            setTheme(<i className="fi fi-sr-sun"></i>)
            toggleTheme(true)
        }
    }
    const [Flag, setFlag] = useState()
    const [Country, setCountry] = useState()
   
    useEffect(() => { 
        const deviceDetector = new DeviceDetector();
        axios.post('https://xattle-api.fegigtech.com/auth/getDeviceInfo',{})
        .then((response) => {      
              setFlag((response.data.country).toLowerCase())
              setCountry(response.data.country)
              return(response.data)
          })
          .then((data)=>{
            const count = getAllInfoByISO(`${(data.country).toLowerCase()}`)
            //console.log(count)
            localStorage.setItem('OMS__CUR', JSON.stringify({name: count.currency, symbol: count.symbol}))
               const userAgent = data.deviceInfo;
           const device = deviceDetector.parse(userAgent);
           localStorage.setItem('OMS__FEI', JSON.stringify(device))
         
           })

        var fixed_top = document.querySelector(`${Nav}`); 
        window.onscroll =()=>{
           
                if (document.body.scrollTop > 250 || document.documentElement.scrollTop > 250){
                    fixed_top.classList.add('fixedTop')
                    fixed_top.classList.add("fadeInUp")
                }
                else {
                    fixed_top.classList.remove('fixedTop');
                    fixed_top.classList.remove("fadeInUp")
                }
            
       
        
        }
        
    },[Flag, Country])
    return (<>
        <Header>
            <Nav className='navbar-expand-lg'>
                <NavLogo src={logo} alt="logo" />
                <Menu className={` ms-auto`}>

                    <MenuBody>
                        <Link className='menuLink' to="#" type="button" >
                            <i className={`fl fl-${Flag}`}></i> | {Country}
                        </Link>
                    </MenuBody>
                    <MenuBody>
                        <TransparentButton className={`menuLink nav-link `}  type="button" onClick={switchTheme}>
                            {theme}
                        </TransparentButton>
                        <input type="checkbox" id="checkbox" onChange={(e) => toggleTheme(e.target.checked)} defaultChecked={toggler}
                            style={{ display: 'none' }} />
                    </MenuBody>

                </Menu>

            </Nav></Header>
    </>);
}

export default AuthHeaders;