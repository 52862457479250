import CurrencyFormat from 'react-currency-format';
import axios from "axios"
import { useEffect, useState } from 'react';
import styles from '../../../style/main.module.css'
import React  from 'react';
const AccountBalance = (props) => {
    const [balance, setBalance] =useState()
    const [fiatCurrency, setFiatCurrency] = useState()
   const [prices, setPrices] = useState()
   const [crypoBalance, setCryptoBalance]=useState()
       const currency = props.currency
       const id = props.id
       const fiat = props.fiat 

       const getRate = (async(coinBalance, coinName)=>{
           return (
        await axios.post(`https://min-api.cryptocompare.com/data/price?fsym=${coinName}&tsyms=BTC`)
            .then((response) => {
                const price = response.data.BTC
                const newCryptoBalance = (price * coinBalance).toFixed(8)
                return  newCryptoBalance
                
                // setCryptoBalance((total))
                // getMain(sum)
                // return (coinName, newCryptoBalance, total)
            })
            .catch((error) => {
                console.log(error)
            }) 
           )
      })


    useEffect(()=>{ 
        setFiatCurrency(currency)
        var totalCoin = 0
        var totalFiat = 0

        const getMain = (async(fiat) =>{
            await axios.post(`https://min-api.cryptocompare.com/data/price?fsym=BTC&tsyms=${fiat}`)
            .then((response) => {
                const price = response.data
                const newPrice = JSON.stringify(price)
                let strip = newPrice.replace(/{|}|"|:/gm,'');
                let result = strip.substring(3, 99);
                totalFiat = result
                setPrices(result)
                //return result
             // console.log((result * sum ).toFixed(2))
                //setValues(price)
               //setBalance((sum * price).toFixed(2))
                
            })
            .catch((error) => {
                console.log(error)
            }) 
     
            }) 

        const getBTC =(async()=>{
                await axios.post('https://xattle-api.fegigtech.com/user/userCoin',
                    {userID:id}).then((response) =>{
                    return response.data.data
                    }).then((data)=>{
                        for (let i = 0; i < data.length; i++) {   
                        var coinName=data[i].short_name
                        var coinBalance = data[i].balance
                           ////////////////////////////////////////////////////////////////
                           var getBTCrate = getRate(coinBalance, coinName)
                           getBTCrate.then((response)=>{
                           totalCoin = (Number(totalCoin) + Number(response)).toFixed(8)
                           setCryptoBalance(totalCoin)
                            const newTotalCoin = ((totalCoin * totalFiat).toFixed(2))
                            setBalance(newTotalCoin)
                           })
                                     
                        }
                    }).catch((error)=>{
                        console.log(error)
                    })
                })
               
getBTC()
getMain(fiat) 
    },[id, currency, fiat]) 
      
    return ( <>
  
                                    <div className={styles.main_balance}>
                                        <CurrencyFormat value={balance} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
                                    isNumericString={true} thousandSeparator={true} prefix={`${fiatCurrency}`} /> 

                                    </div>
                                    <div  className={styles.main_equivalent}> <i className={`fi fi-rr-exchange ${styles.balance_exchanger_icon}`}></i>{crypoBalance} BTC</div>
                                
   
    
    </> );
}
 
export default AccountBalance;