import React, {useState, useEffect} from 'react';
import { Content, Icon, Img, Logo, LogoInner, Main, Text, Text2, Title, Wrapper } from './style';

const NoInternetConnection = (props) => {
    // state variable holds the state of the internet connection
    const [isOnline, setOnline] = useState(true);

    // On initization set the isOnline state.
    useEffect(()=>{
        setOnline(navigator.onLine)
    },[])

    // event listeners to update the state 
    window.addEventListener('online', () => {
        setOnline(true)
    });
    window.addEventListener('offline', () => {
        setOnline(false)
    });

    // if user is online, return the child component else return a custom component
    if(isOnline){
    return(
        props.children
    )
    } else {
        return(<>
        <Wrapper>
            <Logo>
                <LogoInner className="d-block" to="/">
                    <Img className="img-responsive mobile-logo" src={require(`../images/logo.png`)} alt="logo" />
                </LogoInner>
            </Logo>

            <Main>
                <Title >No Internet Available</Title>
                <Content>
                    <Text><Icon className="fi fi-rr-cloud-disabled"></Icon>
                    <Text2>Please check that you are connected to the internet</Text2>
                    </Text>
                </Content>
            </Main>

        </Wrapper>
        </>)
    }
}

export default NoInternetConnection;