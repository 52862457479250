import styled from 'styled-components';

export const Upload = styled.div`
cursor: pointer;
height: 150px;
width: 150px;
background: var(--main);
padding: 50px 40px;
color: var(--font-color-inverse);
border-radius: 5px;
transition: 0.5s;
margin: auto;
:hover{
    background: #ffbf0066;
    color: var(--font-color);
}
`

export const UploadLabel = styled.label`
    cursor: pointer;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-size: 13px;
    text-align: center;
    line-height: 1.5;
`
export const UploadFile = styled.input`
    display: none;
    opacity: 0;
    position: absolute;
    z-index: -1;
`
export const Clearfix = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
min-height: 20px;
}`
export const YellowButton = styled.button`
@media screen and (min-width: 767px)
{
    min-width: 120px;
}

    margin: 0px;
    
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: inherit;
    text-align: center;
    text-decoration: none;
    outline: none;
    padding: 10px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    word-break: keep-all;
    color: rgb(33, 40, 51);
    border-radius: 4px;
    min-height: 24px;
    border: none;
    background-image: none;
    background-color: var(--main);
    height: 40px;
    min-width: 100%;

    :hover {
        background-color: #ffcb2d;
        transition:cubic-bezier(0.6, -0.28, 0.74, 0.05) 0.5s;
    }
`
export const GreenButton = styled.button`
@media screen and (min-width: 767px)
{
    min-width: 120px;
}

    margin: 0px;
    
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: inherit;
    text-align: center;
    text-decoration: none;
    outline: none;
    padding: 10px 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    word-break: keep-all;
    color: var(--font-color-inverse);
    border-radius: 4px;
    min-height: 24px;
    border: none;
    background-image: none;
    background-color: #00b10a;
    height: 40px;
    min-width: 100%;

    :hover {
        background-color: #01eb0f;
        transition:cubic-bezier(0.6, -0.28, 0.74, 0.05) 0.5s;
    }
`