import styled from 'styled-components';

export const DisplayMobile = styled.div`
@media (min-width: 768px){
display:none;
}
display: flex;
`
export const UserLogoMobile = styled.div`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 14px;
    outline: none;
    cursor: default;
    color: #edeff1;
    align-items: center;
    position: relative;
    text-decoration: none;
    margin-left: 8px;
    margin-right: 8px;
    height: 100%;
    @media (min-width: 768px){
        display:none;
        }
        display: flex;
`
export const DropdownMobile = styled.ul`
box-sizing: border-box;
margin: 0px;
background-color: rgb(30, 35, 41);
position: absolute;
box-shadow: rgb(0 0 0 / 8%) 0px 0px 20px;
overflow: hidden;
border-radius: 0px 0px 8px 8px;
width: 100%;
min-width:300px;
left: 0 !important;`

export const Button= styled.button`
padding: 7px 10px;
    font-size: 14px !important;
    color:rgb(var(--white));
    font-weight: 500;
    background-color: var(--main);
    border-color: var(--main);
    cursor: pointer;
    line-height: 14px;
    position: relative;
    transition: .1s;
    text-transform: none;
    margin: 0;
    border-radius: 0.25rem;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    display: flex;
    align-items: center;
    :hover{
        background-color: var(--main-alt);
        border-color: var(--main-alt);
    color: rgb(var(--white));
    }
    :focus{
        box-shadow: 0 0 3px 0px #fdeab0;
        color:  rgb(var(--white));
    }
    i{
        margin-right:5px
    }`
    export const MenuTrigger= styled.button`
    position: relative;
    width: 25px;
    height: 20px;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
    margin-right: 1%;

span {
    display: inline-block;
    transition: all 0.4s;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--link-color);
    border-radius: 4px;
    

}
span:nth-of-type(1) {
    top: 0;
    width:60%;
    margin-left: 10px;
}
span:nth-of-type(2) {
    top: 9px;
    width:40%;
    margin-left: 14px;
}
span:nth-of-type(3) {
    bottom: 0;
    width:100%;
    margin-left: 0;
}

:hover{
    span:nth-of-type(1) {
        margin-left: 0;
    }
    span:nth-of-type(2) {
        margin-left: 0;
    }
    span:nth-of-type(3) {
        margin-left: 0;
    } 
}
:not(.collapsed){
    span:nth-of-type(1) {
        transform: rotate3d(0, 0, 1, 45deg) translate3d(0, 0, 0);
        top: 8px;
        width: 100%;
        margin: 0;
    }
    span:nth-of-type(2) {
        opacity: 0;
    }
    span:nth-of-type(3) {
        transform: rotate3d(0, 0, 1, -45deg);
        top: 8px;
        margin: 0;
    } 
}

@media (min-width: 992px)
{
    display: none;
}`

 export const Logo = styled.img`
 width: 120px;`