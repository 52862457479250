import '../css/font-awesome.min.css';
import '../css/style.css'
import styles from '../css/auth.module.css'
import { useNavigate, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import { Main, Title, Content, Text, Wrapper,  Card, CardHeader, CardBody, CurrencyList, Span, FlexColumn } from './componentStyles';
import '../../market/style/flag-icons-main/css/flag-icons.min.css'
import { CountryDropdown} from 'react-country-region-selector';
import Page404 from './404';
import axios from 'axios';
import AuthHeaders from './AuthHeader';
//import Cookies from "js-cookies"

class SetCurrency extends React.Component {
    state = {
        currencyActive: false,
        currencyName: '',
    }

    
    render() {
        const Currency = () => {
            var items = useLocation()
            const navigate = useNavigate();
            const [fiatList, setFiatList] = useState()
            const [country, setCountry] = useState()
            const [currencySelected, setCurrencySelected] = useState()
            const [currency_id, setCurrency_id] = useState()
            useEffect(() => {
                axios.post('https://xattle-api.fegigtech.com/user/getAllFiats',{})
                    .then((response) => {
                       
                        setFiatList(response.data.data)
                    }).catch((error) => {
                        console.log(error.response)
                    })
            }, [])
          
            
            const processCurrency = (e) => {
                e.preventDefault();
                setProceedBtn(false)
                 const payload = {
                country: country,
                currency_id: currency_id,
                userId: items.state.userId
            }
              
                axios.post('https://xattle-api.fegigtech.com/user/updateCurrency',payload)
                .then((response) => {
                  
                    // const updateCurrencyInfo ={
                    //     currency:currencyName,
                    //     symbol:currencySymbols.substring(1, 2)
                    // }
                   
                    // const updateInfo = {
                    //     country: country,
                    //     currency_id: currency_id,
                    //     demo: userInfo.demo,
                    //     deposit: userInfo.deposit,
                    //     email: userInfo.email,
                    //     firstName: userInfo.firstName,
                    //     lastLog: userInfo.lastLog,
                    //     lastName: userInfo.lastName,
                    //     user_id: userInfo.user_id,
                    //     verificationStatus: "1"
                    // }
                   
                    //Cookies.removeItem('userID');
                    //Assign Coin BTC, ETH, USDT
                    const addBTC = axios.post('https://xattle-api.fegigtech.com/user/assignWallet', {userId:items.state.userId,coin:"BTC"});
                    const addETH = axios.post('https://xattle-api.fegigtech.com/user/assignWallet', {userId:items.state.userId,coin:"ETH"});
                    const addUSDT = axios.post('https://xattle-api.fegigtech.com/user/assignWallet', {userId:items.state.userId,coin:"USDT"});
                    
                     axios.all([addBTC, addETH, addUSDT]).then(axios.spread(function(res1, res2, res3) {
                       //console.log(res1, res2, res3);
                        //localStorage.setItem('OMS__CUR', JSON.stringify(updateCurrencyInfo));
                       // localStorage.setItem('_currentInfo', JSON.stringify(updateInfo))
                        // Cookies.set('__isLoggedIn', true, { expires: 3 })
                        navigate('/regCompleted')

                      })).catch(error => {
                        // react on errors.
                        console.error(error.response);
                      });
                    

                   
                })
                .catch((error) => {
                    console.log(error.response);
                    setProceedBtn( true)

                })
               
            }
            const [proceedBtn, setProceedBtn]= useState(true);

            const processActive = (e) => {
                setActiveId(e.target.id);
            }
            const [activeId, setActiveId] = useState();

            if (items.state === null) {
                return (<>
                    <Page404 />
                </>);
            }
            else {

                //const email = items.state.email
               // const userId = items.state.userId
                const data = fiatList

          
                return (<>
                <AuthHeaders/>
                    <Wrapper styling={`justify-content: center; margin: 70px auto; padding: 40px; ; height:100% !important`}>
                        {/* <Logo>
                        <Link className="d-block" to="" style={{ width: 'fit-content' }}>
                            <img className="img-responsive" src={logo} alt="logo" />
                        </Link> 
                    </Logo> */}
                        <Main>
                            <Title >Currency and<span className='main-color'> Country</span></Title>
                            <Content styling={` width:350px; @media screen and (min-width: 768px){width:600px }`}>
                            <Card>
                                    <CardHeader>
                                        <Text className="main-color">Choose a country and region </Text>
                                    </CardHeader>
                                    <CardBody>
                                    <FlexColumn>
                                    <CountryDropdown  className={styles.selectContainer} value={country} onChange={(val) => {setCountry(val);}} />                                    
                                    </FlexColumn>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardHeader>
                                        <Text className="main-color">Select your currency</Text>
                                    </CardHeader>
                                    {data && <CardBody>
                                        {data.map((items) => (

                                            <CurrencyList  
                                            id={`${items.id}`} 
                                            className={`${activeId === items.id && 'active'}`} 
                                            onClick={(e) => { e.preventDefault(); setCurrencySelected(`You have selected  ${items.name}  -  ${items.symbol.substring(1, 2)} as your default currency`); setCurrency_id(items.id);  processActive(e);}} key={items.id} >

                                                <Span >{items.name} - {items.symbol.substring(1, 2)}</Span>

                                            </CurrencyList>

                                        ))}

                                    </ CardBody>}
                                    <Span> {currencySelected}  </Span>
                                </Card>
                                { proceedBtn ? <button className="btn main-background" onClick={processCurrency}>Proceed</button> : <button className="btn main-background" type="submit" disabled={true}>
					<div className="lds-ring"><div></div><div></div><div></div><div></div></div></button>} 
                            </Content>
                          
                        </Main>
                    </Wrapper>

                </>);
            }
        }
        return (<>
            <Currency />
        </>)
    }
}


export default SetCurrency;