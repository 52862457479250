import { Link } from "react-router-dom";
import styles from '../../style/main.module.css'
import {QRCode} from "react-qrcode-logo";
import { AuthTextbox, AuthTextFlex, AuthTextgroup, FlexColumn, IconBody, IconDiv, TextCopied, TextListChild } from "../wallet/components/bodyStyle";
import CopyToClipboard from "react-copy-to-clipboard";
import { useState } from "react";
import { Card, CardContent, CardHeader, FAQsBody, FAQsContent, FAQsDiv, FAQsHeader } from "./style";
const LinkRules = () => {
    const userId = JSON.parse(localStorage.getItem('_currentInfo')).user_id;
    const [copiedShow, setCopiedShow] = useState(false)

    const hideState = ""
    const showState = <TextCopied>Copied</TextCopied>
    const toggleCopyAddress = () => {
        setCopiedShow(true);
        showAlert()
    };

const showAlert = () => {
    setTimeout(() => {
        setCopiedShow(false);
    }, 2000);
}

    return ( <>
     <div className={`${styles.card} ${styles.half}`}>
                <div className={styles.card_inner}>
                    <div className={styles.card_content}>
                        <div className={styles.card_header}>
                            <div className={styles.card_header_text}>Referral Link</div> 
                            </div>
                        <div className={styles.card_body}>
                        <div className={styles.deposit_wallet_text}>
               Ask your friends to  Scan the QR Code or copy and the address to share with friends
            </div>
         
                        <FlexColumn style={{ alignItems: 'center' }}>
                                            <QRCode value={`https://xattle.fegigtech.com/register?ref=${userId}`} size={120} level={'M'}
                                                eyeRadius={[[10, 10, 0, 10], [10, 10, 10, 0], [10, 0, 10, 10],]}
                                                qrStyle={'squares'} style={{ borderRadius: '10px' }} className="rounded" />
                                            <TextListChild>
                                                <AuthTextgroup className="mb-4" style={{ marginTop: '10px' }}>
                                                {copiedShow ? showState : hideState}
                                                    <AuthTextFlex>
                                                        <input type="radio" className="text" value={`https://xattle.fegigtech.com/register?ref=${userId}`} style={{ display: "none" }} />
                                                        <AuthTextbox placeholder={`https://xattle.fegigtech.com/register?ref=${userId}`} type="text" value="" disabled={true} />
                                                        <IconDiv className="icon-div">
                                                            <CopyToClipboard text={`https://xattle.fegigtech.com/register?ref=${userId}`}
                                                                onCopy={() => toggleCopyAddress}>
                                                                <IconBody onClick={toggleCopyAddress}><i className="fi fi-sr-copy-alt"></i></IconBody>
                                                            </CopyToClipboard>

                                                        </IconDiv>
                                                    </AuthTextFlex>
                                                </AuthTextgroup>
                                            </TextListChild>



                                        </FlexColumn>
                            </div>
                        {/* <div className={styles.card_body}> {userId && <TradeHistoryMini userId={userId}/>}
                        </div> */}
                    </div>
                </div>
            </div>
            <div className={`${styles.card} ${styles.half}`}>
                <div className={styles.card_inner}>
                    <div className={styles.card_content}>
                        <div className={styles.card_header}>
                            <div className={styles.card_header_text}>Rules</div> 
                            <Link className={styles.note_last} to="#"  data-bs-toggle="modal" data-bs-target="#FAQs" >
                                <div className={styles.note_last_item}>FAQs</div>
                                <div className={styles.note_last_item_icon}> 
                                <i className="fi fi-rr-angle-circle-right"></i>
                                </div></Link>
                            </div>
                            <div className={styles.card_body}>
                            <p>Share your Xattle Referral Link with a friend who does not have an account with us.</p>
                            <p>Referees Must make a deposit of more than $100 withen 14 days after registration. </p>
                            <p>Disclaimer: You can only claim one reward for each referral.</p>
                            <p>See FAQ for more information</p>
                            </div>
                        {/* <div className={styles.card_body}> {userId && <TradeHistoryMini userId={userId}/>}
                        </div> */}
                    </div>
                </div>
            </div>

              
    <div className="modal fade" id="FAQs" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className= "modal-dialog modal-dialog-centered modal-lg">
    <div className={`  ${styles.card_inner} modal-content `}>
    <div className={`${styles.card_header} modal-header`}>
        <div className={`  ${styles.card_header_text}`} id="FAQsLabel"> Referral/Affiliate FAQ</div>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> 
      <div className={`${styles.card_body} modal-body`}>
      <Card >
                            <CardContent>
                                <CardHeader>
                                    <FAQsDiv>
                                          <FAQsContent>
                                            <FAQsHeader>
                                           How to use Xattle Referral
                                            </FAQsHeader>
                                            <FAQsBody>
                                                <p>Expirience a new way of earning rewards by inviting friends to join our community. when the friend you invited registers for a Xattle account and makes a deposit upto $100, you will both receive 10% each for trading bonus from us</p>
                                            </FAQsBody>
                                        </FAQsContent>
                                    </FAQsDiv>
                                    <FAQsDiv>
                                          <FAQsContent>
                                            <FAQsHeader>
                                           How to use Xattle Referral
                                            </FAQsHeader>
                                            <FAQsBody>
                                                <p>Expirience a new way of earning rewards by inviting friends to join our community. when the friend you invited registers for a Xattle account and makes a deposit upto $100, you will both receive 10% each for trading bonus from us</p>
                                            </FAQsBody>
                                        </FAQsContent>
                                    </FAQsDiv>
                                    <FAQsDiv>
                                          <FAQsContent>
                                            <FAQsHeader>
                                           How to use Xattle Referral
                                            </FAQsHeader>
                                            <FAQsBody>
                                                <p>Expirience a new way of earning rewards by inviting friends to join our community. when the friend you invited registers for a Xattle account and makes a deposit upto $100, you will both receive 10% each for trading bonus from us</p>
                                            </FAQsBody>
                                        </FAQsContent>
                                    </FAQsDiv>
                                </CardHeader>
                            </CardContent>
                        </Card>
      </div>
    </div>
  </div>
</div>
    </> );
}
 
export default LinkRules;