import styled from 'styled-components';

export const Card = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
display: flex;
width: 100%;
padding: 8px;
`
export const CardContent = styled.div`
box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex: 1 1;
    flex-direction: column;`

    export const CardHeader = styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    flex: 1 1;
    padding-top: 16px;
    padding-bottom: 16px;
    border: 0;`

    export const CardHeaderContent =  styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    font-size: 12px;
    line-height: 16px;  
    width:75%;

    @media screen and (min-width: 767px)
{
    font-size: 16px;
    line-height: 24px;
}`
export const NewsImage = styled.img`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
width: 32px;
height: 32px;
flex-shrink: 0;
border-radius: 100%;
`
export const NewsDiv = styled.div`
box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    align-items: center;
    height: fit-content;`
export const NewsHeader = styled.div`

    box-sizing: border-box;
    margin: 0px 8px 0px 0px;
    min-width: 0px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: var(--font-color);
    
    @media screen and (min-width: 1023px)
{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

@media screen and (min-width: 767px)
 {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}
`
export const NewsTags = styled.div`
font-size: 10px;
font-weight: 300;
font-style: italic;
color: var(--main);`
export const NewsContent = styled.div`
box-sizing: border-box;
    margin: 0px 0px 0px 24px;
    min-width: 0px;
    display: flex;
    height: fit-content;
    justify-content: center;
    flex-direction: column;`

    export const NewsBody = styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    color: rgb(112, 122, 138);`