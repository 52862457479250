import { CardContent, Clearfix, FlexColumn, TextList, TextListIcon, TextListItem, YellowButton } from "./tradeComponents";
import styles from '../../style/main.module.css'
import { GetRandomString } from "../wallet/components/random";
import axios from "axios";
import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";

const TraderSummary = (props) => {
    const currency = props.currency
    const userId = props.userId
    const startTrade = () =>{
        const token = GetRandomString(62)
        const time = Math.floor(Date.now() / 1000)
        const expires = Math.floor(time + 43200)  //12 hrs
        const status = "pending"
        axios.post('https://xattle-api.fegigtech.com/auth/createToken',{
        userId: userId,
        token: token,
        time: time,
        expires: expires,
        status: status,
            }) 
        .then(() =>{
            window.open(`https://trade.logo.com/trader/?id=${userId}&t=${token}&e=${expires}`,'_blank')
        })
        .catch(err =>{
            console.log(err.response)
        })       
    }
    useEffect(() => {
        axios.post('https://xattle-api.fegigtech.com/user/getOtherBalance', { userId: userId })
            .then(response => {
                return response.data.data
            }).then(data => {
                setTotalBalance(data.fiat)
                setBonusBalance(data.bonus)
            })
    }, [userId]);

    useEffect(() => {
        axios.post('https://xattle-api.fegigtech.com/user/wonLoss', { userId: userId })
            .then(response => {
                // console.log(response)
                return response.data
            }).then(data => {
                console.log(data)

             setTotalTrades(data.won.length + data.loss.length+data.pending.length)
             const t = data.won.length + data.loss.length + data.pending.length
            const ratioWon = (data.won.length / t) * 100
            const ratioLoss = (data.loss.length / t) * 100
            if (data.won.length > 0){
                 setTotalWon(ratioWon.toFixed(2))
                setTotalLoss(ratioLoss.toFixed(2))
            }
            else{
                setTotalWon(0)
                setTotalLoss(0)
            }
               
                setTotalPending(data.pending.length)
              
            })
    }, [userId]);

    useEffect(() => {
        const users =  JSON.parse(localStorage.getItem('_currentInfo'))
        if (users.verificationStatus === "0"){
            setTradeButton('Invalid user')
         } else if (users.verificationStatus < "3"){
            setTradeButton('Account Verification Required')
        }else if (users.verificationStatus === "3" ){
            setTradeButton(<YellowButton onClick={startTrade}>Open Trade Center</YellowButton>)
        }
    },[])
    const [tradeButton, setTradeButton]= useState()
    const [totalTrades, setTotalTrades] = useState()
    const [totalBalance, setTotalBalance] = useState()
    const [bonus, setBonusBalance] = useState()
    const [totalWon, setTotalWon] = useState()
    const [totalLoss, setTotalLoss] = useState()
    const [totalPending, setTotalPending] = useState()
    return (<>
        <CardContent>
            <FlexColumn>
                <TextList>
                    <TextListIcon>
                        <i className={`fi fi-sr-money ${styles.balance}`}></i>
                    </TextListIcon>

                    <TextListItem>Fiat Balance</TextListItem>
                    <TextListItem> - <CurrencyFormat value={totalBalance} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
                                    isNumericString={true} thousandSeparator={true} prefix={`${currency}`} /> 
                                    </TextListItem>
                </TextList>
                <TextList>
                    <TextListIcon>
                        <i className={`fi fi-sr-gift ${styles.balance}`}></i>
                    </TextListIcon>

                    <TextListItem>Bonus Balance</TextListItem>
                    <TextListItem> - <CurrencyFormat value={bonus} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
                                    isNumericString={true} thousandSeparator={true} prefix={`${currency}`} /></TextListItem>
                </TextList>
                <TextList>
                    <TextListIcon>
                        <i className={`fi fi-sr-chat-arrow-grow ${styles.balance}`}></i>
                    </TextListIcon>

                    <TextListItem>Profit Ratio</TextListItem>
                    <TextListItem>- {totalWon}%</TextListItem>
                </TextList>
                <TextList>
                    <TextListIcon>
                        <i className={`fi fi-sr-chat-arrow-down ${styles.balance}`}></i>
                    </TextListIcon>

                    <TextListItem>Losses Ratio</TextListItem>
                    <TextListItem>- {totalLoss}%</TextListItem>
                </TextList>
                <TextList>
                    <TextListIcon>
                        <i className={` fi fi-sr-stats ${styles.balance}`}></i>
                    </TextListIcon>

                    <TextListItem>Total Orders</TextListItem>
                    <TextListItem>- {totalTrades}</TextListItem>

                </TextList>
                <TextList>
                    <TextListIcon>
                        <i className={` fi fi-sr-hourglass-end ${styles.balance}`}></i>
                    </TextListIcon>

                    <TextListItem>Total Opened Order</TextListItem>
                    <TextListItem>- {totalPending}</TextListItem>

                </TextList>
                <TextList>
                    <TextListIcon>
                        <i className={` fi  fi-sr-apps ${styles.balance}`}></i>
                    </TextListIcon>

                    <TextListItem>Leverage</TextListItem>
                    <TextListItem>- 1:500</TextListItem>
                </TextList>

            </FlexColumn>
            <Clearfix /> 
            {tradeButton}
            {/* <a href={`https://logo.com/trial/trader/?id=${userId}&t=${GetRandomString(65)}`} target="_blank" rel="noreferrer">
               
            </a> */}
        </CardContent>
    </>);
}

export default TraderSummary;