import './css/style.css'
import { Link, useNavigate  } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';
import  './css/loadProgressMin.css'
import React  from 'react';
import { GetRandomInt, GetRandomString } from '../market/pages/wallet/components/random';
import Cookies from 'js-cookie'
import { AuthContainerAlt, AuthTextbox, AuthTextFlex, AuthTextgroup, FlexColumn, Icon, IconBody, IconDiv, Wrapper } from './components/componentStyles';
import AuthHeaders from './components/AuthHeader';
 
const Login = () => {
    const d = new Date();
    let year = d.getFullYear();
	const [email, setEmail]=useState('');
	const [password, setPassword]=useState('');
	const [loginBtn, setloginBtn]= useState(true);
	const [loginResponse, setLogingresponse] = useState('');
	const [passwordShown, setPasswordShown] = useState(false);

	const navigate  = useNavigate ();

	axios.defaults.withCredentials = true;
	const loginUser = (e)=> {
		e.preventDefault();
		setloginBtn(false)
		
		axios.post('https://xattle-api.fegigtech.com/user/login',{
			useremail:email,
			password:password
		}).then((response)=>{
			//console.log(response)
				// check if userEmail is verified
						let email= response.data.user.email
						let userId = response.data.user.user_id
					if (response.data.user.verificationStatus === '0'){
						const token = GetRandomString(62)
						
						let userName = response.data.user.firstName + ' ' + response.data.user.lastName
						navigate('/verify', {state: {email:email, userId:userId, token:token, userName:userName}})						
					}
					else if (response.data.user.firstName === null || response.data.user.lastName === null) {
						navigate('/userBios', {state: {email:email, userId:userId}})
					}
					else if (response.data.user.country === null || response.data.user.currency_id === null) {
						navigate('/currency', {state: {email:email, userId:userId}})
					}
					else {
							const OTP = GetRandomInt(6);
						axios.get(`https://xattle-api.fegigtech.com/mailer/emailOTP?mailTo=${email}&token=${OTP}`).then(()=>{
						const time = Math.floor(Date.now() / 1000)
						const expires = Math.floor(time + 600)  //10 minutes
						const messageId = GetRandomString(36)
						
						axios.post('https://xattle-api.fegigtech.com/auth/sendOTP',{
							userId: userId,
							messageId: messageId,
							code: OTP,
							time: time,
							expires: expires
								})
								.then(()=> {
									navigate('/auth', {state: {user:response.data.user, messageId:messageId}})
								}) 
						})
						.catch(err => {
							console.error(err)
						})
					
												
					}
				
			
		}).catch((error)=>{
			if (error){
				const msg = error.response.data.message
				setLogingresponse(<div className='alert alert-danger p-2 alert-dismissible fade show' role="alert">{msg}</div>);
				setloginBtn(true)
				setTimeout(function () {setLogingresponse('') }, 10000)
			}
		})
	}

	useEffect(()=>{

		const check = Cookies.get('__isLoggedIn')
		
		if (check) {
		const userInfo = JSON.parse(localStorage.getItem('_currentInfo'))
		const updateInfo = {
			country: userInfo.country,
			currency_id: userInfo.currency_id,
			demo: userInfo.demo,
			deposit: userInfo.deposit,
			email: userInfo.email,
			firstName: userInfo.firstName,
			lastLog: userInfo.lastLog,
			phone: userInfo.phone,
			lastName: userInfo.lastName,
			user_id: userInfo.user_id,
			verificationStatus: userInfo.verificationStatus,
			phoneVerified: userInfo.phoneVerified
		}
		localStorage.setItem('_currentInfo', JSON.stringify(updateInfo))
		axios.post('https://xattle-api.fegigtech.com/user/getFiat',{currencyId:userInfo.currency_id})
						.then((response)=>{
						  const curr =  response.data.currency
						  let sym = curr.symbol
						  let result = sym.substring(1, 2);
						  const items ={
							name: curr.name,
							symbol: result
						  }
						  localStorage.setItem('OMS__CUR', JSON.stringify(items))
						  navigate('/home/dashboard')
						})
			// navigate('/home/dashboard')
		}
	},[navigate])
	const hideState = <Icon className="fi fi-sr-eye"></Icon>
	const showState = <Icon className="fi fi-sr-eye-crossed"></Icon>
	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	  };
    return (

        <>

	<AuthHeaders/>
          <Wrapper>
        <div className="container-fluid user-auth row">
			<AuthContainerAlt >
				
			
				<div className="form-container">
					<FlexColumn>
					
						<div className="text-center">
							<h2 className="title-head h3">member <span className='main-color'>login</span></h2>
							 <p className="info-form">Send, receive and securely store your coins in your wallet</p>
						</div>
						
						<form  onSubmit={loginUser} >
								<div className='mt-10'>{loginResponse}</div>
						
								<AuthTextgroup className="mb-4">
								<AuthTextFlex>
								<AuthTextbox  name="email" id="txt_uname" placeholder="EMAIL" type="email" value={email} onChange={(e)=> setEmail(e.target.value)} required />
							</AuthTextFlex>
							</AuthTextgroup>
						
							<AuthTextgroup className="mb-4">
								<AuthTextFlex>
								<AuthTextbox  name="password" id="txt_pwd" placeholder="PASSWORD" type={passwordShown ? "text" : "password"} value={password} onChange={(e)=> setPassword(e.target.value)} required
								/>
								<IconDiv className="icon-div">
								<IconBody  id="pass-display" onClick={togglePassword}>{passwordShown ? showState : hideState}</IconBody>
								</IconDiv>
								</AuthTextFlex>
							</AuthTextgroup>
							
							<FlexColumn>
								{loginBtn ? <button className="btn main-background" type="submit" name="login">Login</button>:
								<button className="btn main-background" type="submit" name="login" disabled={true}>
									<div className="lds-ring"><div></div><div></div><div></div><div></div></div></button>}
								
								<p className="text-center"><Link to="/forgot" className=' main-color'>forgot password</Link> | don't have an account ? <Link to="/register" className=' main-color'>Register Now</Link>  </p>
								<br/>
								<p className="text-center"> <Link to="/home" className=' main-color'>Back to Home</Link>
							</p>
							</FlexColumn>
							
						</form>
						
						
					</FlexColumn>
				</div>
		
				<p className="text-center copyright-text">Copyright © {year} Xattle All Rights Reserved</p>
				
			</AuthContainerAlt>
		</div>
       
    </Wrapper>
 
        </>
     );
}
 
export default Login;