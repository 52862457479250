import styled from "styled-components";
export const IconBTN =styled.button`
background-color: transparent;
    border: none;
    color: var(--main);
    align-content: baseline;
    font-size: 15px;
    :hover{
        color: rgb(255 235 155);
    }`
export const FlexColumn = styled.div`
display:flex;
flex-direction:column;`
export const FlexRow = styled.div`
display:flex;
flex-direction:row;
a{
  padding: 0 10px;
  color: var(--font-color-inverse);
  font-weight: 700;
}`

export const LoadingLg = styled.div`

display: inline-block;
position: relative;
width: 50px;
height: 50px;
margin:auto;

div {
box-sizing: border-box;
  display: block;
  position: absolute;
  width: 60px;
  height: 60px;
  margin: 0px;
  border: 3px solid #ffce00;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffce00 transparent transparent transparent;

  nth-child(1) {
animation-delay: -0.45s;
}
nth-child(2) {
animation-delay: -0.3s;
}
nth-child(3) {
animation-delay: -0.15s;
}
@keyframes lds-ring {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
}`
export const CurrencyWrapper = styled.div`

  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px; 
  flex: 1 1;
  @media screen and (min-width: 767px)
 {
  font-size: 30px; 
 
}
`