import axios from 'axios'
import { useEffect, useState } from 'react'
import styles from '../../style/main.module.css'
import { sendOTP } from './sendOTP'
import { AuthTextbox, AuthTextFlex, AuthTextgroup, FlexColumn,  IconBody, IconDiv, LoadingLg, LoadingSm, PhoneVerification, PhoneVerificationIcon, PhoneVerificationIconButton, PhoneVerificationText, Text, TextError } from './style'
const SettingsModals = (props) => {
    const modalType = props.type
     const phone = props.phone
     const userId = props.userId
    const [modalTitle, setModalTitle] = useState()
    const [modalContent, setModalContent] = useState()
    const [authCode, setAuthCode] = useState()
    var [checkCodeInput, setCheckCodeInput] = useState()
    var [authResendBtn, setAuthResendBtn] = useState(false)
    var [showResendBtn, setShowResendBtn] = useState(false)
   
   useEffect(() =>{
     const userInfo = JSON.parse(localStorage.getItem('_currentInfo'))
    const resendOTP = () => {
      setAuthResendBtn(true)
      sendOTP(userId, phone)
      setTimeout(() => {
          setAuthResendBtn(false)
      }, 5000)
    }
      const firstOTP = () => {
        setAuthResendBtn(true)
        setShowResendBtn(true)
         sendOTP(userId, phone)
        setTimeout(() => {
            setAuthResendBtn(false)
        }, 5000)
}
    const firstState = <><Text onClick={firstOTP}>Get Code</Text></>
    const hideState = <><Text onClick={resendOTP}>Resend Code</Text></>
	const showState =  <LoadingSm><div></div><div></div><div></div><div></div></LoadingSm>
	const toggleResend = () => {
		setAuthResendBtn(!authResendBtn);
       
	  };
        if (modalType ==='verifiyPhone') {
        setModalTitle('Verify Phone')
        setModalContent(<>Enter the code sent to your phone
        <AuthTextgroup>
            <AuthTextFlex>
                <AuthTextbox type="text" onChange={(e)=>{setAuthCode(e.target.value); setCheckCodeInput(e.target.value.length)}}/>
             <IconDiv className="icon-div">
            {showResendBtn? <IconBody onClick={toggleResend}>{authResendBtn ? showState : hideState}</IconBody>: 
            <IconBody>{firstState}</IconBody>} 
								</IconDiv>
              </AuthTextFlex>
        </AuthTextgroup>
       </>)
      // console.log(checkCodeInput)
    }

    if (checkCodeInput === 6) {
      setModalContent(<>
      <FlexColumn>
       <LoadingLg><div></div><div></div><div></div><div></div></LoadingLg>
      
        </FlexColumn>
      </>)
       const token =  localStorage.getItem('__SFK')
       if (token !== null) {
       let message_id = token.slice(0, 36);
       let code = token.slice(37, 50);
        axios.post('https://xattle-api.fegigtech.com/auth/verifyOTP', { userId: userId, messageId: message_id, code: code })
            .then(response => {
                return response.data
            }).then(data => { 
                if (data.valid) {
                  axios.post('https://xattle-api.fegigtech.com/user/updatePhoneStatus', { userId: userId, status: 1 })
                                .then(() => {
                                  const updateInfo = {
                                    country: userInfo.country,
                                    currency_id: userInfo.currency_id,
                                    demo: userInfo.demo,
                                    deposit: userInfo.deposit,
                                    email: userInfo.email,
                                    firstName: userInfo.firstName,
                                    lastLog: userInfo.lastLog,
                                    phone: userInfo.phone,
                                    lastName: userInfo.lastName,
                                    user_id: userInfo.user_id,
                                    verificationStatus: userInfo.verificationStatus,
                                    phoneVerified: "1"
                                }
                                localStorage.setItem('_currentInfo', JSON.stringify(updateInfo))
                                    console.log('user Phone verified')
                                    setModalContent(<>
                                      <FlexColumn>
                                        <PhoneVerification>
                                          <PhoneVerificationIcon>
                                            <i className="fi fi-sr-check"></i> 
                                          </PhoneVerificationIcon>
                                          <PhoneVerificationText>
                                            Phone Verified
                                          </PhoneVerificationText>
                                          <PhoneVerificationIconButton data-bs-dismiss="modal" aria-label="Close" onClick={(e)=>{window.location.reload();}}>
                                            Close
                                          </PhoneVerificationIconButton>
                                        </PhoneVerification>
                                    
                                        </FlexColumn>
                                      </>)
                                      localStorage.removeItem('__SFK')
                                })
          
                }
                else {
                    console.log('not verified')
                }
            })
            .catch(error => {
                console.log(error.response)
                setModalContent(<>
               <TextError> An error occurred! Please try again later or kindly generate a new OTP</TextError>
                  <AuthTextgroup>
                      <AuthTextFlex>
                          <AuthTextbox type="text" onChange={(e)=>{setAuthCode(e.target.value); setCheckCodeInput(e.target.value.length)}}/>
                       <IconDiv className="icon-div">
                      <IconBody onClick={toggleResend}>{authResendBtn ? showState : hideState}</IconBody>
                          </IconDiv>
                        </AuthTextFlex>
                  </AuthTextgroup>
                 </>)
            })
    }
    else {
      setModalContent(<>
        <TextError> An error occurred! Please try again later or kindly generate a new OTP</TextError>
           <AuthTextgroup>
               <AuthTextFlex>
                   <AuthTextbox type="text" onChange={(e)=>{setAuthCode(e.target.value); setCheckCodeInput(e.target.value.length)}}/>
                <IconDiv className="icon-div">
               <IconBody onClick={toggleResend}>{authResendBtn ? showState : hideState}</IconBody>
                   </IconDiv>
                 </AuthTextFlex>
           </AuthTextgroup>
          </>)
    }
  }
   },[modalType,authCode, checkCodeInput, authResendBtn, userId, phone, showResendBtn])
   
    return ( <>
    <div className="modal fade" id="modalUsage" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className= "modal-dialog modal-dialog-centered">
    <div className={`${styles.card_inner} modal-content `}>
    <div className={`${styles.card_header} modal-header`}>
        <div className={`  ${styles.card_header_text}`} id="AccountVerificationLabel">{modalTitle}</div>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> 
      <div className={`${styles.card_body} modal-body`}>
        {modalContent}
      </div>
    </div>
  </div>
</div></> );
}
 
export default SettingsModals;