import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { GetRandomString } from "../components/random";
import CurrencyFormat from 'react-currency-format'
import '../../../../auth/css/loadProgressMin.css'
import styles from '../../../style/main.module.css'
import { Clearfix, FlexColumn, Verification, VerificationIcon, VerificationText, YellowButton } from "../components/bodyStyle";

const WithdrawFiat = ({accountBalance}) => {
    const id = JSON.parse(localStorage.getItem('_currentInfo')).user_id;
    const Fiat = JSON.parse(localStorage.getItem('OMS__CUR')).name;
    const currSym = JSON.parse(localStorage.getItem('OMS__CUR')).symbol;
    const [coin, setCoin] = useState('BTC')
    const [coinEquv, setCoinEquv] = useState(0)
    const [coinList, setCoinList] = useState()
    const [mainCur, setMainCur] = useState(0)
    const [walletId, setWalletId] = useState()
    const [proceedBtn, setProceedBtn] = useState('Proceed')
    useEffect(() => {
        const fetchCoin = (async () => {
            await axios.post('https://xattle-api.fegigtech.com/user/userCoin',
                { userID: id })
                .then((response) => {
                    return response.data.data
                }).then((data) => {
                    setCoinList(data)
                    setWalletId(data[0].wallet_id)
                    setCoin(data[0].short_name)
                }).catch((error) => {
                    console.log(error)
                })
        })
        fetchCoin()
    }, [id])
    const [valueAmount, setValueAmount] = useState(0)
    const [valueFee, setValueFee] = useState("0.00")
    const getFee=(amount) =>{
        const fee = Number(amount * 0.02)
        
        setValueFee(fee.toFixed(2))
    }
    const getEquiv = (value) => {

        const init = Number(value)

        axios.post(`https://min-api.cryptocompare.com/data/price?fsym=${Fiat}&tsyms=${coin}`)
            .then((response) => {

                const price = response.data
                const newPrice = JSON.stringify(price)
                let strip = newPrice.replace(/{|}|"|:|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z/gm, '');
                const newCryptoRate = (init * Number(strip)).toFixed(8)
                setMainCur(init)
                setCoinEquv(newCryptoRate)
                getFee(init)
            }).catch((error) => {
                console.log(error)
            })
    }

    const data = coinList

    const GenerateTransaction = (e) => {
        e.preventDefault();
        
        const debitAmount = (Number(mainCur)+Number(valueFee)).toFixed(2)
        if (debitAmount > Number(accountBalance)) {
            alert('Insufficient Balance')
            return
        }
       // console.log(debitAmount)
        const t_id = GetRandomString(16)
        // const trans_data = {
        //     trans_id: t_id,
        //     amount: mainCur,
        //     equv: coinEquv,
        //     coinType: coin,
        //     fiat: Fiat,
        //     userId: id,
        //     syb: currSym,
        //     wallet: coinWallet,
        //     coinFull: coinFullname,
        //     openDate: Math.floor(Date.now() / 1000),
        //     closeDate: Math.floor((Date.now() / 1000) + 600),
        // }
        setProceedBtn(<div className="lds-ring"><div></div><div></div><div></div><div></div></div>)
        axios.post('https://xattle-api.fegigtech.com/user/createTransaction',
            {
                trans_id: t_id,
                amount: mainCur,
                equv:  coinEquv,
                coinType: coin,
                fiat: Fiat,
                userId: id,
                trans_type: 'deposit',
                date: Math.floor(Date.now() / 1000),
                status: "pending",
                walletId: walletId
            })
            .then((response) => {
                return response.data
            }).then((data) => {
                //console.log(trans_data)

                axios.post('https://xattle-api.fegigtech.com/user/updateTransaction',{
                    status:"processed",
                    transId:t_id,
                    amount:coinEquv
                })
                 //fundFiatBalance
                axios.post('https://xattle-api.fegigtech.com/user/updateFiat',{userId: id, amount:"-"+ debitAmount})
                .then((response) => {
                    console.log(response.data)
                    setDataProcessed(true)
                })
                .catch((error) => {
                    console.log(error.response)

                })
                
            }).catch((error) => {
                console.log(error)
            })
    }
const [dataProcessed, setDataProcessed] =useState(false)
    return (<>
{dataProcessed ? <>
 <FlexColumn>
                                        <Verification>
                                          <VerificationIcon>
                                            <i className="fi fi-sr-check"></i> 
                                          </VerificationIcon>
                                          <VerificationText>
                                            Transfer Successful
                                          </VerificationText>
                                          <Clearfix></Clearfix>
                                          <YellowButton data-bs-dismiss="modal" aria-label="Close" onClick={(e)=>{window.location.reload();}}>
                                            Close
                                          </YellowButton>
                                        </Verification>
                                    
                                        </FlexColumn>

</>

: 

<form onSubmit={GenerateTransaction}>

<div className="input-group mb-3">
<div className={styles.swap_coin_text_balance}>
            <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
            Enter Amount in Fiat and select coin Wallet to complete your withdraw, If you wish to Withdraw to an external wallet or Bank Account, please contact support.
            </div>
        </div>
    <div className={styles.swap_coin_input}>
        <input type="text" className={` ${styles.text_input} form-control`} placeholder={`Enter Amount in ${Fiat}`} name="txtbitamount" value={valueAmount} onChange={(e) => { setValueAmount(e.target.value); getEquiv(e.target.value); }} />
        <div className={styles.swap_coin_text_balance}>
            <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
            Fee: {currSym}{valueFee} (2%)
               
            </div>
        </div>
        
    </div>

    <span className={`${styles.modal_coin} ${styles.align_base} input-group-text`} id="basic-addon2">
        <div className="dropdown">
            <button className={`${styles.modal_coin_button} btn dropdown-toggle`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <img className={styles.coin_table_logo} alt={styles.coin_name} src={require(`../../../style/SVG/${coin}.svg`)} />

            </button>
            {
                data && <ul className={`dropdown-menu ${styles.dropdown_m} `} aria-labelledby="dropdownMenuButton1">
                    {data.map((items) => (

                        <li key={items.wallet_id}><div className={` ${styles.dropdown_z} dropdown-item`} id={items.short_name} onClick={(e) => { setCoin(items.short_name); setWalletId(items.wallet_id); setValueAmount(0); getEquiv(0); setValueFee("0.00"); }}>
                            <img className={styles.coin_deposit_logo} alt={styles.coin_name} src={require(`../../../style/SVG/${items.short_name}.svg`)} onClick={(e) => { setCoin(items.short_name); setWalletId(items.wallet_id); setValueAmount(0); getEquiv(0);setValueFee("0.00");  }} />
                            <div className={styles.coin_deposit_coin_name} onClick={(e) => { setCoin(items.short_name); setWalletId(items.wallet_id); setValueAmount(0); getEquiv(0); setValueFee("0.00"); }}>{items.short_name}</div> </div></li>

                    ))}
                </ul>
            }
        </div>
    </span>

</div>
<div className={styles.coin_deposit_base}>
    <button className="btn btn-success" style={{ minWidth: '100%' }}>{proceedBtn}</button>
    <div className={`ms-auto ${styles.deposit_equivalent}`}>
        <CurrencyFormat value={mainCur} displayType={'text'} decimalScale={2} fixedDecimalScale={true}
            isNumericString={true} thousandSeparator={true} prefix={`${currSym}`} />
        <i className={`fi fi-rr-exchange ${styles.deposit_exchanger_icon}`}></i>{coinEquv} {coin}
    </div>

</div>

</form> }
       
    </>);
}

export default WithdrawFiat;