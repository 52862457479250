import styled from 'styled-components';

export const FlexColumn = styled.div`
display:flex;
flex-direction:column;`
export const CardContent = styled.div`
@media screen and (min-width: 767px)
{
    align-items: flex-start;
}
    box-sizing: border-box;
    margin: 0px 0px 12px 0;
    min-width: 0px;
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;`
export const FlexRow = styled.div`
display:flex;
flex-direction:row;
width:100%;
justify-content: space-around;`


export const TextList = styled.div`
display:flex;
flex-direction:row;
color: rgb(112, 122, 138);`
export const TextListIcon = styled.div`
display:block;
`
export const TextListItem = styled.div`
margin-left:10px;
line-height:1.75
`
export const Clearfix = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
min-height: 20px;
}`
export const YellowButton = styled.button`
@media screen and (min-width: 767px)
{
    min-width: 120px;
}

    margin: 0px;
    
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: inherit;
    text-align: center;
    text-decoration: none;
    outline: none;
    padding: 10px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    word-break: keep-all;
    color: rgb(33, 40, 51);
    border-radius: 4px;
    min-height: 24px;
    border: none;
    background-image: none;
    background-color: var(--main);
    height: 40px;
    min-width: 100%;

    :hover {
        background-color: #ffcb2d;
        transition:cubic-bezier(0.6, -0.28, 0.74, 0.05) 0.5s;
    }
`
export const TextGroup = styled.div`
flex-direction: column;
display: flex;
margin-bottom: 5px;
padding: 0 10px;
@media screen and (min-width: 768px){
flex-direction: row;
}
`
export const TextLabel = styled.label`
padding: 6px;
font-size: 15px;
font-weight: 700;`

export const VerificationTextBox = styled.input`
background: var(--background-color);
border: 0;
color: var(--font-color);
border-radius:5px;
padding: 5px 10px;
font-size: 12px;
:focus-visible{
    outline: none
}`
export const DisplayHalf = styled.div
`display: flex;
flex-direction: column;
width: 100%;
margin: 0 0 10px 0;
@media screen and (min-width: 768px){
width: 50%;
}`

export const H4 = styled.h4`
font-size:14px`
export const H3 = styled.h3`
font-size:18px`
export const H2 = styled.h2`
font-size:22px`
export const H1 = styled.h1`
font-size:24px`
export const ListHeading = styled.div`
display: flex;
flex-direction: column;
${H3}, ${H4}{
    margin-bottom: 5px;
    line-height: 15px;
    color:var(--font-color);
    
}
${H4}{
    font-weight:400;
    font-style: italic;
}
`
export const ListBody = styled.div`
display: flex;
flex-direction:column;
user-select: none;
margin: 40px;
`
export const Button = styled.button`   
`
export const IDtype = styled.div`
display: flex;
flex-direction:row;
cursor: pointer;
padding: 10px 0;
:hover{
    border: 2px solid rgb(112,122,138);
    border-radius: 10px;
    color: rgb(112,122,138);
    padding: 0 5px;
    ${TextLabel}{
        font-weight: 700;
        
    }
}
${TextLabel}{
    font-size: 14px;
    font-weight: 500;
    color: rgb(112,122,138);
    margin: auto 0;
}
${TextListIcon}{
    margin-left: auto!important;
}
:focus-visible{
    border: 2px solid rgb(112,122,138);
    padding:10px 0;
}
`
export const active =`
border: 2px solid rgb(112,122,138);
`
export const MiniIcon = styled.img`
height: 40px;
width: 40px;
border-radius: 100%;
`
export const CameraButton = styled.div`
align-self: center;
${YellowButton}{
    margin-top: 10px;
}
`
export const RowColumn = styled.div`
display:flex;
flex-direction:column;
width: 100%;
justify-content: center;
${YellowButton}:first-child {
    margin: 0 0 5px 0;
};
${YellowButton} {
    margin: 5px 0;
}
@media screen and (min-width: 767px)
{
    flex-direction:row;
    ${YellowButton}:first-child {
        margin: 0 5px 0 0;
    };
    ${YellowButton}{
        margin: 0 5px;
    }
}`
export const CoinBlock = styled.div`
box-sizing: border-box;
margin: 0px 0px 0px 16px;
min-width: 0px;
display: flex;
flex-flow: column wrap;`
export const CoinName = styled.div`
box-sizing: border-box;
margin: 0px 8px 0px 0px;
min-width: 0px;
font-weight: 600;
font-size: 24px;
line-height: 17px;
color: var(--font-color);
@media screen and (min-width: 767px){
   font-weight: 700;
    font-size: 25px;
    line-height: 15px; 
}
`
export const CoinInitial = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
color: rgb(112, 122, 138);`
export const CoinImage = styled.img`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
width: 32px;
height: 32px;
flex-shrink: 0;`
export const TextListChild = styled.div`
margin-left: 10px;
line-height: 1.5;
font-size:15px;
font-weight: 700;
color: var(--main);
`
export const TableAmountDeposit = styled.div`
font-size: 16px;
    color: var(--success);
    font-weight: 600;
    line-height: 20px;
@media  screen and (min-width: 768px) {
    font-size: 20px;
}`
export const TableAmountWithdraw = styled.div`
font-size: 16px;
    color: var(--failed);
    font-weight: 600;
    line-height: 20px;
@media  screen and (min-width: 768px) {
    font-size: 20px;
}`
export const IconDiv = styled.div`
box-sizing: border-box;
    margin: 0;
    min-width: 0;`
    export const AuthTextgroup = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
width: 100%;
position: relative;
min-height: 12px;
`
export const AuthTextFlex = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
display: inline-flex;
position: relative;
align-items: center;
line-height: 1.6;
border: 1px solid rgb(71, 77, 87);
border-radius: 4px;
height: 48px;
background-color: transparent;
width: 100%;
input{
    color: var(--font-color);;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
}
input:-webkit-autofill{
    transition: color 99999s ease-in-out 0s, background-color 99999s ease-in-out 0s;
    -webkit-text-fill-color: var(--font-color);;
}
:hover{
    border-color: rgb(240, 185, 11);
}
${IconDiv}{
    flex-shrink: 0;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 14px;
}
.PhoneInput{ 
    width: 100%;
    padding: 0 10px;
}
.PhoneInputInput{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    width: 100%;
    height: 100%;
    padding: 0px;
    outline: none;
    border: none;
    background-color: inherit;
    opacity: 1;
}`
export const AuthTextbox = styled.input`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
width: 100%;
height: 100%;
padding: 0px;
outline: none;
border: none;
background-color: inherit;
opacity: 1;
`

export const IconBody = styled.div`
box-sizing: border-box;
    margin: 0px 4px 0px 0px;
    min-width: 0px;
    display: flex;
    align-items: center;`
export const Icon = styled.i`
box-sizing: border-box;
    margin: 0px 0px 0px 4px;
    min-width: 0px;
    color: rgb(94,102,115);
    font-size: 20px;
    width: 1em;
    height: 1em;`
export const TextCopied = styled.div`
position: absolute;
width: 100%;
text-align: center;
z-index: 99999;
background: #ffd100ab;
padding: 0;
color: #000000;
margin-top: 12px;
`
export const Span = styled.div`
display: flex;
margin: 0 auto;
border-radius: 10px;
font-weight: 700;

`
export const CoinList = styled.div` 
border-radius: 5px;
font-weight: 700;
box-sizing: border-box;
margin: 10px 0;
min-width: 0px;
display: flex;
position:relative;
align-items: center;
border: none;
outline: none;
padding-left: 0px;
padding-right: 0px;
width: 50%;
user-select: none;
cursor: pointer;
:hover{
  color: var(--font-color);
  text-decoration: none;
  background-color: var(--main);
}
@media screen and (min-width: 767px){
    height: 50px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 12px;
    width:50%;
}
@media screen and (min-width: 1023px){
    padding: 10px;
    width: 50%;
    display: flex;
    flex-direction: row;
    margin: 10px auto;
}
`
export const Card = styled.div`
padding: 25px;
border: 1px #f5f5f5 solid;
border-radius: 15px;
box-shadow: #cccccc 1px 1px 2px 0px;
margin: 10px 0;
display: flex;
flex-direction: column;
justify-content:space-around;`
export const CardBody = styled.div`
    padding: 0 15%;
    margin: 20px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;`
export const SuccessText = styled.div`
color: var(--success);`
export const FailedText = styled.div`
color: var(--failed);`
export const WarningText = styled.div`
color: var(--main);`


export const Verification = styled.div`
align-self: center;
font-size: 40px;
color: lime;
flex-direction: column;
display: flex;`

export const VerificationText = styled.div`
font-size: 14px;
  text-align: center;
`
  export const VerificationIcon = styled.div`
  text-align: center;`
