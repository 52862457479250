import {Main, Title, Content, Text, Text2, Wrapper} from './componentStyles'
import '../css/style.css'
import React  from 'react';
import AuthHeaders from './AuthHeader';
const Page404 = () => {
    document.title = `Xattle | 404 Not Found`;
    return ( <>
    	<AuthHeaders/>
        <Wrapper>
           

            <Main>
                <Title >Page Not Found</Title>
                <Content>
                    <Text>404 Error<strong>{ }</strong></Text>
                    <Text2>Please Check That You're on the Right Page</Text2>
                </Content>
            </Main>

        </Wrapper></>);
}
 
export default Page404;
