import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../css/index.module.css'
import { ButtonLg, ButtonWrapper, Column, ColumnItem1, ColumnItem2, ColumnRow, H2, H3, IndexSection, IndexSectionDescription, IndexSectionWrapper, LargeImage, Paragraph, ParagraphText } from '../style/bodyStyle';

const IndexAbout = () => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(true)
    useEffect(() => {

        const handleResize = () => {
            if (window.innerWidth > 767) {
                setIsMobile(false)
            }
            else if (window.innerWidth < 767) {
                setIsMobile(true)
            }
        }
        handleResize()
        window.addEventListener("resize", handleResize)
    }, [isMobile])
    return (<>
     <div className={styles.main_container}>
     <div className={styles.index_welcome}>
                <div className={styles.index_welcome_left}>
                    <h1 className={styles.left_welcome}>
                        <div className={styles.left_welcome_text}>
                            Our community getting bigger
                        </div>
                    </h1>
                    <ParagraphText data-bn-type="text">
                    <H3>
                    Join over 6,000,000 users from all over the world satisfied with our services
                    </H3>
                        </ParagraphText>
                   
                </div>
                
                        <div className={styles.index_welcome_right}>
                            <div className={styles.index_welcome_right_content}>
                                <img className={styles.index_welcome_img} alt="top_lazy_img" src={require(`../../images/our_people.png`)}  />
                            </div>


                        </div>
                  


            </div>
            <IndexSection>
          <IndexSectionWrapper>
          <H2>
          Welcome to Xattle
                </H2>
                <IndexSectionDescription>
                At Xattle, we believe that everyone should have the freedom to earn, hold, spend, share and give their money - no matter who you are or where you come from without 3rd party financial interferance.
                </IndexSectionDescription>
            </IndexSectionWrapper>
            </IndexSection>

            <IndexSection>
                <IndexSectionWrapper>
                    <ColumnRow style={{ alignItems: 'center' }}>
                        <ColumnItem1>
                        <H2>
                               Our Mission
                            </H2>
                            <Paragraph>
                                <ParagraphText data-bn-type="text">
                                We are the bridge between the blockchain network and your personal finance. Our mission is to provide the core infrastructure services for redifinig the world’s crypto space and your personal finance.
                                </ParagraphText>
                            </Paragraph>
                        </ColumnItem1>

                        <ColumnItem2>
                            <LargeImage alt="" className="mica-lazy-img" src={require('../../images/our_mission.png')} style={{ backgroundColor: 'transparent' }} />
                        </ColumnItem2>
                    </ColumnRow>
                </IndexSectionWrapper>
            </IndexSection>


            <IndexSection>
                <IndexSectionWrapper>
                    <ColumnRow style={{ alignItems: 'center' }}>
                        <ColumnItem1 style={{order: '1',marginBottom: '25px'}}>
                        <LargeImage alt="" className="mica-lazy-img" src={require('../../images/our_vision.png')} style={{ backgroundColor: 'transparent' }} />

                        </ColumnItem1>

                        <ColumnItem2><H2>
                               Our Vision
                            </H2>
                            <Paragraph>
                                <ParagraphText data-bn-type="text" style={{textAlign: 'left',order: '1',marginBottom: '25px'}}>
                                We try to increase the freedom of money globally. You own your money at tour will, be your bank and control your money without external interferance. 
                                We believe that by spreading this freedom, we can help lives around the world
                                </ParagraphText>
                            </Paragraph>
                        </ColumnItem2>
                    </ColumnRow>
                </IndexSectionWrapper>
            </IndexSection>

            <IndexSection>
                <IndexSectionWrapper>
                    <Column style={{ textAlign: 'center', alignItems: 'center' }}>

                        <H2 >
                           Our Users our Priority
                        </H2>
                        <Paragraph>
                                <ParagraphText data-bn-type="text">
                                Users are at the heart of everything we do. From the beginning, 
                                we made user protection our top priority. That’s why we’ve embedded state-of-the-art security algorithms, 
                                measures and strict data privacy controls across the entire Xattle platform.
                                </ParagraphText>
                            </Paragraph>
                            <LargeImage alt="" className="mica-lazy-img" src={require('../../images/our_people_2.png')} style={{ backgroundColor: 'transparent' }} />

                    </Column>
                </IndexSectionWrapper>
            </IndexSection>

            <IndexSection>
                <IndexSectionWrapper>
                    <Column>

                        <H2 style={{ textAlign: 'center' }}>
                            Start earning today
                        </H2>
                        <ButtonWrapper>
                            <ButtonLg className="btn" onClick={(e) => { navigate('/register') }}>Get Started</ButtonLg>

                        </ButtonWrapper>

                    </Column>
                </IndexSectionWrapper>
            </IndexSection>

     </div>
   
    </> );
}
 
export default IndexAbout;